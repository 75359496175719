/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-07-16",
    versionChannel: "nightly",
    buildDate: "2024-07-16T00:05:34.758Z",
    commitHash: "6e93927f631fb317a6b40f031457c47d3e399e27",
};
